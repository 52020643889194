import { useEffect, useState } from 'react';

import Brand from './Pages/PC/Brand';
import Video from './Pages/PC/Video';
import Header from './components/Header';
import MobileVideo from './Pages/Mobile/MobileVideo';
import Services from './Pages/PC/Services';
import MobileServices from './Pages/Mobile/MobileServices';
import CallLink from './components/CallLink';
import Features from './Pages/PC/Features';
import MobileFeatures from './Pages/Mobile/MobileFeatures';

import Field from './Pages/PC/Field';
import Footer from './components/Footer';

import './App.css';
import MobileBrand from './Pages/Mobile/MobileBrand';
import MobileField from './Pages/Mobile/MobileField';

function App() {
  const [isMobile, setMobile] = useState(false);
  const device = navigator.userAgent;

  useEffect(() => {
    if(device.indexOf('iPhone') > -1 || device.indexOf('Android') > -1)
      setMobile(true);
    else
      setMobile(false);
  }, [device]);
  return(
    <div className='App'>
      <Header isMobile={isMobile} />
      { isMobile 
        ? <>
            <MobileVideo />
            <CallLink phoneNumber='1588-0325' />
            <MobileServices />
            <MobileFeatures />
            <MobileField />
            <CallLink phoneNumber='1588-0325' />
            <MobileBrand />
          </>
        : <>
            <Video />
            <Services />
            <Features />
            <Field />
            <Brand />
          </>
      }
      <Footer />
    </div>
  )
}

export default App;