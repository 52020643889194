import './BrandItem.css';

const BrandItem = ({imgSrc, isMobile}) => {
  return(
    <div className={isMobile ? 'MobileBrandItem' : 'BrandItem'}>
      <img src={imgSrc} />
    </div>
  )
}

export default BrandItem;