import { featureList1, featureList2, title_benefit } from '../../settings/conf';

import FeatureItem from '../../components/FeatureItem';
import Title from '../../components/Title';

import './Features.css';
const Features = () => {
  return(
    <div className='container'>
      <Title title={title_benefit} />
      <div className='feature_wrapper'>
        { featureList1.map((no) => {
          return <FeatureItem key={no} no={no} />
        })}
      </div>
      <div className='feature_wrapper'>
        { featureList2.map((no) => {
          return <FeatureItem key={no} no={no} />
        })}
      </div>
    </div>
  )
}

export default Features;