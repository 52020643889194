import './ProdItem.css';

const ProdItem = ({imgSrc, content, isMobile}) => {
  const styles = {
    justifyContent: isMobile ? 'center' : ''
  }
  return(
    <div
      className={ isMobile ? 'MobileProdItem' : 'ProdItem'} 
      style={styles}
    >
      <img src={imgSrc} content={content} />
      <div className={isMobile ? 'mContent' : 'content'}>
        <div>1. {content.type}</div>
        <div>2. {content.con1}</div>
        {content.con2 ? <div>3. {content.con2}</div> : null}
        {content.con2 ? <div>4. {content.size}</div> : <div>3. {content.size}</div>}
        {content.con2 ? 
          <div style={{color: 'blue', fontSize: '14px'}}>❉. {content.spec}</div> : null
        }
      </div>
    </div>
  )
}

export default ProdItem;