import React from 'react';

const CallLink = ({ phoneNumber }) => {
  return (
    <div className='call_link'>
      <a 
        href={`tel:${phoneNumber}`}
        style={{textDecoration: 'none', color: 'orange'}}
      >
        문의전화 : {phoneNumber}
      </a>
    </div>
  );
}

export default CallLink;