import logo from '../assets/etc/logoWhite.png';
import { footer_content } from '../settings/conf.js';

import './Footer.css';

const Footer = () => {
  return(
    <div className='Footer'>
      <div className='footer_info'>
        <span>{footer_content.info}</span>
        <span>{footer_content.addr}</span>
        <span>{footer_content.copy}</span>
        <span>{footer_content.tel}</span>
      </div>
      <div className='footer_img'>
        <img src={logo} />
      </div>
    </div>
  )
}

export default Footer;