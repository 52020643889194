import ProdItem from '../../components/ProdItem';
import stick from '../../assets/images/prod/stick.png';
import board from '../../assets/images/prod/board.png';
import digital from '../../assets/images/prod/digital.png';
import Slider from 'react-slick';
import Title from '../../components/Title';

import { service_content, title_type } from '../../settings/conf';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Services = () => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return(
    <div className='container'>
      <Title title={title_type} isMajor />
      <Slider { ...settings } >
        <ProdItem imgSrc={digital} content={service_content.digital}/>
        <ProdItem imgSrc={board} content={service_content.board}/>
        <ProdItem imgSrc={stick} content={service_content.stick}/>
      </Slider>
    </div>
  )
}

export default Services;