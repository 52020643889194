import { appName, stage, debug, ec2Debug } from "./appSetting";
import { dev, prod } from "./awsSetting";

import ceco_1 from '../assets/images/field/compressor/ceco_1.png';
import ceco_2 from '../assets/images/field/compressor/ceco_2.jpg';
import insoop_1 from '../assets/images/field/compressor/insoop_1.jpg';
import insoop_2 from '../assets/images/field/compressor/insoop_2.jpg';
import macha from '../assets/images/field/compressor/macha_2.png';
import nul_1 from '../assets/images/field/compressor/nul_1.png';
import ok from '../assets/images/field/compressor/ok.png';
import sa_1 from '../assets/images/field/compressor/sa_1.jpg';
import sa_2 from '../assets/images/field/compressor/sa_2.jpg';
import soryang from '../assets/images/field/compressor/soryang.png';
import sune from '../assets/images/field/compressor/sune.jpg';
import young from '../assets/images/field/compressor/young.png';

import b_ggol from '../assets/images/brand/ggol.png';
import b_hanshin from '../assets/images/brand/hanshin3.png';
import b_kyung from '../assets/images/brand/kyung.png';
import b_macha from '../assets/images/brand/macha.png';
import b_ok from '../assets/images/brand/ok2.png';
import b_okki from '../assets/images/brand/okki.png';
import b_pho from '../assets/images/brand/pho.png';
import b_soryang from '../assets/images/brand/soryang.png';
import b_sune from '../assets/images/brand/sune.png';
import b_young from '../assets/images/brand/young.png';
import b_tori from '../assets/images/brand/tori.png';
import b_private from '../assets/images/brand/private.png';
import b_kis from '../assets/images/brand/kis.jpg';
import b_kcp from '../assets/images/brand/kcp.png';
import b_hanwool from '../assets/images/brand/hanwool.png';


// 국세청 사업자조회 Open API //
// 참고사이트 : data.go.kr
// 검색 : 국세청 -> Open API
// 아이디 / 비번 : 카카오톡으로 로그인 하기.
// 유효기간 : 2024년 07월 08일 
// url과 serviceKey는 https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${license.serviceKey} 
// 형태로 입력해야 함.
export const enquireLicenseInfo = {
  serviceKey: 'OdcEoBC79yO%2FviLYtnHrqcC6SkRloaLQEIbiq1AMYictHwOzgb12If99G5haOWo5tyFWGMG0kd%2Fun6DjYR%2B%2FYg%3D%3D',
  url : 'https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey='
};

const apiKey = "F43653B64C291AFDE25B7365A64970DE";
// const prod = dev;
const awsConfig = stage === "prod" ? prod : dev;

export default {
  appName, stage, debug, ec2Debug,
  apiKey,
  ...awsConfig,
  MAX_FILE_SIZE: 1000000, // 이미지 업로드 시 max size = 1MB
  superAdminPhoneNo: "01053174673"
};

export const filedImages = [ 
  ceco_1, ceco_2, insoop_1, insoop_2, macha, nul_1, sa_1, sa_2, ok, soryang, sune, young
]; 
export const brandImages1 = [ b_hanwool, b_hanshin, b_macha, b_ok, b_kyung ];
export const brandImages2 = [ b_young, b_pho, b_okki, b_sune, b_soryang ];
export const brandImages3 = [ b_ggol, b_tori, b_private, b_kis, b_kcp ];

export const m_brandImages1 = [b_hanwool, b_hanshin, b_ok];
export const m_brandImages2 = [b_macha, b_kyung, b_pho];
export const m_brandImages3 = [b_young, b_okki, b_soryang];
export const m_brandImages4 = [b_sune, b_private, b_kcp];
export const m_brandImages5 = [b_ggol, b_kis, b_tori];

export const featureList1 = [1, 2, 3];
export const featureList2 = [4, 5, 6];

export const service_content = {
  stick: {
    type: '부착형',
    con1: '테이블에 부착 또는 POP에 부착해 사용',
    size: '크기: 10cm x 7cm, 두께 : 0.08cm',
  },
  board: {
    type: '주문 보드형',
    con1: '손님에게 직접 가져다 주는 타입.',
    size: '크기: 16cm x 7cm, 두께 : 0.3cm',
  },
  digital: {
    type: 'Digital QR형',
    con1: '손님에게 직접 가져다 주는 타입.',
    con2: 'QR코드가 주기적으로 변해 QR코드 유출 방지.',
    size: '크기: 15cm x 7cm, 두께 : 0.8cm',
    spec: '외부로 유출한 QR코드로 장난 주문 불가능.'
  }
}

export const footer_content = {
  info: '대표이사: 천성우 | 상호명: (주)피에스디엘 | 사업자등록번호: 528-87-01357',
  addr: '주소: 경기도 성남시 수정구 대왕판교로815 정보보호클러스터 466호',
  copy: 'Copyright 2021 (주)PSDL All rights reserved.',
  email: 'email: sunnh@psdl.co.kr',
  tel: 'Tel: 031-984-7027',
}

export const name_smart = '스마트오더';
export const name_onl = "'오늘'";
export const name_ui = '의';
export const title_type = '서비스 타입';
export const title_benefit = '6가지 혜택!';
export const title_field = '설치 사례';
export const title_brand = '협력사들';
export const title_4 = '4가지 없는';
export const title_tel = '(1588-0325)';