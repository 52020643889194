import { filedImages, title_field } from '../../settings/conf';

import Slider from 'react-slick';
import Title from '../../components/Title';
import FieldItem from '../../components/FieldItem';

const Field = () => {
  const settings = {
    lazyLoad: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
  }
  return(
    <div className='container'>
      <Title title={title_field} isMajor />
      <Slider { ...settings }>
        { filedImages.map((img, idx) => 
          <FieldItem key={idx} imgSrc={img} />
        )}
      </Slider>
    </div>
  )
}

export default Field;