
const region = "ap-northeast-2";

export const dev = {
  s3: {
    REGION: region,
    BUCKET: 'psdl-oneul-storage',
    publicUrl:
      'https://psdl-oneul-storage.s3.ap-northeast-2.amazonaws.com/public',
  },
  apiGateway: {
    REGION: region,
    USER: {
      URL: "https://26t8zzar2i.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "user",
    },
    SHOP: {
      URL: "https://1fip4tm6if.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "shop",
    },
    ORDER: {
      URL: "https://ikzw33q1ql.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "order",
    },
    TAG: {
      URL: "https://0uo80kd1lk.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "tag",
    },
    SMS: {
      URL: 'https://itqapba6gg.execute-api.ap-northeast-2.amazonaws.com',
      API_NAME: 'sms',
    },
    POS: {
      URL: 'https://3ygypkwoyh.execute-api.ap-northeast-2.amazonaws.com',
      API_NAME: 'pos',
    },
  },

  // Tapga AWS Cognito 접속용.
  cognito: {
    REGION: 'ap-northeast-2',
    USER_POOL_ID: "ap-northeast-2_FVn0bRuVW",
    APP_CLIENT_ID: "23r70u9fgej1smuna8jeb9rb8g",
    IDENTITY_POOL_ID: "ap-northeast-2:ee10000b-90a3-43c0-ad23-37beb62e0418",
    IDENTITY_POOL_NAME: "dev-taptag-Auth"
  },

  iot: {
    REGION: region,
    ENDPOINT: 'wss://aghkh91el9khi-ats.iot.ap-northeast-2.amazonaws.com/mqtt',
  },
};

export const prod = {
  s3: {
    REGION: region,
    BUCKET: 'psdl-oneul-storage',
    publicUrl:
      'https://psdl-oneul-storage.s3.ap-northeast-2.amazonaws.com/public',
  },
  apiGateway: {
    REGION: region,
    USER: {
      URL: "https://ufjhvkqk3c.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "user",
    },
    SHOP: {
      URL: "https://w7ixtbsxw9.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "shop",
    },
    ORDER: {
      URL: "https://x72fp95lrl.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "order",
    },
    TAG: {
      URL: "https://ixmfajs08g.execute-api.ap-northeast-2.amazonaws.com",
      API_NAME: "tag",
    },
    SMS: {
      URL: 'https://90zerhxvv5.execute-api.ap-northeast-2.amazonaws.com',
      API_NAME: 'sms',
    },
    POS: {
      URL: 'https://a3s0o3yjvh.execute-api.ap-northeast-2.amazonaws.com',
      API_NAME: 'pos',
    },
  },

  // Taptag AWS Cognito 접속용.
  cognito: {
    REGION: 'ap-northeast-2',
    USER_POOL_ID: "ap-northeast-2_vZHPogwg0",
    APP_CLIENT_ID: "7euvus1r9267oilbs0068mhlao",
    IDENTITY_POOL_ID: "ap-northeast-2:9c20016d-a5cb-476d-b39f-3544820e12c7",
    IDENTITY_POOL_NAME: "prod-taptag-Auth"
  },

  iot: {
    REGION: region,
    ENDPOINT: 'wss://aghkh91el9khi-ats.iot.ap-northeast-2.amazonaws.com/mqtt',
  },
};