import Title from '../../components/Title';
import BrandItem from '../../components/BrandItem';

import { m_brandImages1, m_brandImages2, m_brandImages3 } from '../../settings/conf';
import { m_brandImages4, m_brandImages5, title_brand } from '../../settings/conf';
import './MobileBrand.css';

const MobileBrand = () => {
  
  return(
    <div className='MobileBrand' style={{marginTop: '30px'}}>
      <Title title={title_brand} isMobile={true}/>      
      <div className='mobile_brand_wrapper'>
        { m_brandImages1.map((img, idx) =>
          <BrandItem key={idx} imgSrc={img} isMobile={true} />
        )}
      </div>
      <div style={{height: '10px'}} />
      <div className='mobile_brand_wrapper'>
        { m_brandImages2.map((img, idx) => 
          <BrandItem key={idx} imgSrc={img} isMobile={true} />
        )}
      </div>
      <div style={{height: '10px'}} />
      <div className='mobile_brand_wrapper'>
        { m_brandImages3.map((img, idx) => 
          <BrandItem key={idx} imgSrc={img} isMobile={true} />
        )}
      </div>
      <div style={{height: '10px'}} />
      <div className='mobile_brand_wrapper'>
        { m_brandImages4.map((img, idx) => 
          <BrandItem key={idx} imgSrc={img} isMobile={true} />
        )}
      </div>
      <div style={{height: '10px'}} />
      <div className='mobile_brand_wrapper'>
        { m_brandImages5.map((img, idx) => 
          <BrandItem key={idx} imgSrc={img} isMobile={true} />
        )}
      </div>
    </div>
  )
}

export default MobileBrand;