import getFeatures from '../utils/get-features-img';
import './FeatureItem.css';

const FeatureItem = ({no, isMobile}) => {
  
  return(
    <div className={isMobile ? 'Mobile_FeatureItem' : 'FeatureItem'}>
      <img src={getFeatures(no)} />
    </div>
  )
}

export default FeatureItem;