import Feature1 from '../assets/images/features/page-2-1.png';
import Feature2 from '../assets/images/features/page-2-2.png';
import Feature3 from '../assets/images/features/page-2-3.png';
import Feature4 from '../assets/images/features/page-2-4.png';
import Feature5 from '../assets/images/features/page-2-5.png';
import Feature6 from '../assets/images/features/page-2-6.png';

const getFeatures = (no) => {
  switch(no) {
    case 1: return Feature1;
    case 2: return Feature2;
    case 3: return Feature3;
    case 4: return Feature4;
    case 5: return Feature5;
    case 6: return Feature6;
  }
}

export default getFeatures;