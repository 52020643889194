import { name_onl, name_smart, title_4 } from '../../settings/conf';

import FeatureItem from '../../components/FeatureItem';

const MobileFeatures = () => {
  const list = [1, 5, 3, 2];
  
  return(
    <div className='mobile_container' style={{marginTop: '80px'}}>
      <Title title={title_4} />
      {list.map((no) => 
        <FeatureItem key={no} no={no} isMobile={true} />
      )}
    </div>
  )
}


const Title = ({title}) => {
  return (
    <div className='mTitle'>
    <div>{title}</div>
    <div style={{height: '5px'}} />
    <div className="first-title">
      <div>{name_smart}</div>
      <div style={{width: '8px'}} />
      <div className='onl-second'>{name_onl}</div>
    </div>
  </div>
  )
}

export default MobileFeatures;