import stick from '../../assets/images/prod/stick.png';
import board from '../../assets/images/prod/board.png';
import digital from '../../assets/images/prod/digital.png';

import ProdItem from '../../components/ProdItem';
import Title from '../../components/Title';
import Slider from 'react-slick';

import { service_content, title_type } from '../../settings/conf';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const MobileServices = () => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  return (
    <div className='mobile_container' style={{marginTop: "30px"}}>
      <Title title={title_type} isMajor isMobile={true}/>
      <Slider className='mobile-slide' { ...settings }>
        <ProdItem imgSrc={digital} content={service_content.digital} isMobile={true} />
        <ProdItem imgSrc={board} content={service_content.board} isMobile={true} />
        <ProdItem imgSrc={stick} content={service_content.stick} isMobile={true} />
      </Slider>
    </div>
  )
}

export default MobileServices;