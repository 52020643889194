import './FieldItem.css';

const FieldItem = ({imgSrc, isMobile}) => {
  return(
    <div className={isMobile ? 'MobileFieldItem' : 'FieldItem'}>
      <img src={imgSrc} />
    </div>
  )
}

export default FieldItem;