import logo from '../assets/etc/logo.png';
import hamburg from '../assets/etc/hamburg.png';
import { useNavigate } from 'react-router-dom';

import './Header.css';

const Header = ({isMobile}) => {
  console.log('isM: ', isMobile);
  const nav = useNavigate();
  return(
    <div className={isMobile ? 'MobileHeader' : 'Header'}>
      <img
        className={isMobile ? 'mobile_img' : ''}
        src={logo} 
        onClick={()=>nav('/', { replace: true })} 
      />
      <button className={isMobile ? 'mobile_img' : ''}>
        <img src={hamburg} />
      </button>
    </div>
  )
}

export default Header;