import Title from '../../components/Title';
import BrandItem from '../../components/BrandItem';

import { brandImages1, brandImages2, brandImages3, title_brand } from '../../settings/conf';

import './Brand.css';

const Brand = () => {
  
  return(
    <div className='Brand'>
      <Title title={title_brand} />
      <div className='brand_wrapper'>
        { brandImages1.map((img, idx) =>
          <BrandItem key={idx} imgSrc={img} />
        )}
      </div>
      <div style={{height: '30px'}} />
      <div className='brand_wrapper'>
        { brandImages2.map((img, idx) => 
          <BrandItem key={idx} imgSrc={img} />
        )}
      </div>
      <div style={{height: '30px'}} />
      <div className='brand_wrapper'>
        { brandImages3.map((img, idx) => 
          <BrandItem key={idx} imgSrc={img} />
        )}
      </div>
    </div>
  )
}

export default Brand;