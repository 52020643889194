import { filedImages, title_field } from "../../settings/conf";
import Slider from "react-slick";
import Title from "../../components/Title";
import FieldItem from "../../components/FieldItem";

const MobileField = () => {
  const settings = {
    lazyLoad: true,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return(
    <div className='mobile_container' style={{marginTop: '70px'}}>
      <Title title={title_field} isMajor isMobile={true} />
      <Slider { ...settings } style={{width: '100%'}}>
        { filedImages.map((img, idx) => 
          <FieldItem key={idx} imgSrc={img} isMobile={true} />
        )}
      </Slider>
    </div>
  )
}

export default MobileField;