import gif from '../../assets/video/dqr-gif-8fr.gif';


import './Video.css';

const Video = ({isMobile}) => {
  return(
    <div className='container'>
      <div className='company'>
        <div style={{color: 'orange'}}>보안 전문</div>
        <div >회사에서 만든</div>
      </div>
      <div className='video_container'>
        <div className='title_wrapper'>
          <div style={{marginBottom: '5px'}}>안전한</div>
          <div style={{marginBottom: '5px'}}>스마트오더</div>
          <div style={{color: '#34c3af'}}>{`'오늘'`}</div>
        </div>
        <img src={gif} />
      </div>
    </div>
  )
}

export default Video;