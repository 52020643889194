import { name_onl, name_smart, name_ui, title_tel } from "../settings/conf";

const Title = ({title, isMajor, isMobile }) => {

  return(
    <div className={ isMobile ? 'mTitle' : "title"}>
      <div className="first-title">
        <div>{name_smart}</div>
        <div style={{width: '8px'}} />
        <div className={`${isMajor ? 'onl-major' : 'onl-second'}`}>{name_onl}</div>
        <div style={{width: '3px'}} />
        <div>{name_ui}</div>
      </div>
      <div>{title}</div>
      { !isMobile && <div style={{fontSize: '20px'}}>{title_tel}</div> }
    </div>
  )
}

export default Title;

